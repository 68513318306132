




























































































































































































































































































import draggable from 'vuedraggable';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import {
  useGiftCondition,
  giftConditionTypeOptions,
  intervalOptions,
  GiftItem,
  GiftData,
  GIFT_CONDITION_TYPE,
} from '@/admin/gift';
import { useNotification } from '@/composition/notification';
import { DisplayDate, getOptionsTitle } from '@/admin/util';
import { useConfirm } from '@/composition/confirm';
import FcNumberForm from '@/components/FcNumberForm.vue';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';
import FcDate from '@/components/FcDate.vue';

export default defineComponent({
  name: 'GiftCondition',
  components: {
    draggable,
    FcNumberForm,
    FcRoleLoading,
    FcRoleDeny,
    FcDate,
  },
  props: {
    giftConditionId: {
      type: String,
      require: false,
      default: '',
    },
    giftConditionType: {
      type: String,
      require: false,
      default: '',
    },
  },
  setup(props, context) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('gifts'));
    const { confirmDialog } = useConfirm();

    const { giftCondition } = useGiftCondition(props.giftConditionId, props.giftConditionType);
    const items = computed(() => giftCondition.viewGifts);
    const displayGiftConditionId = computed(() => giftCondition.giftConditionId || '');
    const isRegister = (item: GiftData) => {
      return !!giftCondition.registerAlertItems.find((id: string) => id === item.giftId);
    };
    const isEdit = (item: GiftData) => {
      return item.isEdit;
    };
    const notification = useNotification();
    const isSaving = ref(false);
    const headers = [
      { text: 'ステータス', value: 'isInActivePeriodGift' },
      { text: '特典名', value: 'giftId' },
      { text: '数量', value: 'quantity' },
      { text: '有効期間開始日', value: 'activeStartDate' },
      { text: '有効期間終了日', value: 'activeEndDate' },
      { text: '', value: 'actions', sortable: false, align: 'end' },
    ];
    const active = ref(false); // 特典条件追加ダイアログ表示フラグ

    const openDialog = () => {
      active.value = true;
    };
    const editDialog = (
      isEdit: boolean,
      index: number,
      giftItems: GiftItem[],
      giftId: string,
      quantity: number,
      isExpiredGift: boolean,
      isInActivePeriodGift: boolean,
      activeStartDate: number,
      activeEndDate?: number
    ) => {
      giftCondition.editItems.isEdit = isEdit;
      giftCondition.editItems.index = index;
      giftCondition.giftItems = giftItems;
      giftCondition.giftData.giftId = giftId;
      giftCondition.giftData.quantity = quantity;
      giftCondition.expirationStartProperty.expirationDate = new DisplayDate(activeStartDate);
      if (activeEndDate) {
        giftCondition.expirationEndProperty.expirationDate = new DisplayDate(activeEndDate);
      }
      giftCondition.giftData.isExpiredGift = isExpiredGift;
      giftCondition.giftData.isInActivePeriodGift = isInActivePeriodGift;
      active.value = true;
    };
    const cancel = () => {
      active.value = false;
      giftCondition.giftData = {
        giftId: '',
        quantity: 1,
        isExpiredGift: false,
        isInActivePeriodGift: false,
        isEdit: false,
      };
      giftCondition.expirationStartProperty.expirationDate = new DisplayDate();
      giftCondition.expirationEndProperty.expirationDate = new DisplayDate();
      giftCondition.editItems = { isEdit: false };
    };

    // 付与する特典条件の追加
    const add = () => {
      if (
        !giftCondition.giftData.giftId ||
        (giftCondition.giftData.quantity === 0 && !giftCondition.giftData.quantity)
      ) {
        // 特典名、数量、有効期限開始日は必須入力
        alert('必須項目が未入力です');
        return;
      } else if (
        (giftCondition.giftConditionType === GIFT_CONDITION_TYPE.BIRTHDAY ||
          giftCondition.giftConditionType === GIFT_CONDITION_TYPE.DURATION ||
          giftCondition.giftConditionType === GIFT_CONDITION_TYPE.EACH_SUBSCRIPTION_PAYMENT ||
          giftCondition.giftConditionType === GIFT_CONDITION_TYPE.SUBSCRIPTION_PAYMENT_COUNT) &&
        !giftCondition.expirationStartProperty.expirationDate.value
      ) {
        alert('必須項目が未入力です');
      } else if (
        giftCondition.expirationStartProperty.expirationDate.value &&
        giftCondition.expirationEndProperty.expirationDate.value &&
        giftCondition.expirationStartProperty.expirationDate.value >=
          giftCondition.expirationEndProperty.expirationDate.value
      ) {
        // 有効期間は時間まで指定するため、指定する際は有効期間が存在するようにする
        alert('有効期間開始日は有効期間終了日より前の日付にしてください');
        return;
      }
      giftCondition.addGiftCondition();
      active.value = false;
    };

    const remove = async (item: GiftData) => {
      if (!(await confirmDialog('本当に削除しますか？'))) return;
      const index = giftCondition.viewGifts.indexOf(item);
      giftCondition.viewGifts.splice(index, 1);
      giftCondition.isDeletedFlg = true;
    };

    // 特典条件を保存する
    const save = async () => {
      if (!(await confirmDialog('本当に保存しますか？'))) return;
      giftCondition.isSaving = true;
      try {
        if (displayGiftConditionId.value) {
          await giftCondition.saveGiftCondition(displayGiftConditionId.value);
          await giftCondition.init();
          notification.notify('変更しました。');
        } else {
          await giftCondition.createGiftCondition();
          notification.notify('作成しました。');
          context.root.$router.push('/gifts');
        }
      } catch (error) {
        notification.error(error);
      } finally {
        giftCondition.isDeletedFlg = false; // 保存時、削除フラグもリセットする
        giftCondition.isSaving = false;
      }
    };

    // 選択された特典の有効期限が切れているか判定する
    const checkExpiredGift = (giftId: string) => {
      giftCondition.giftData.isExpiredGift =
        giftCondition.giftItems.find((giftItem) => giftItem.value === giftId)?.isExpiredGift ?? false;
    };

    if (context.root.$route.query.giftId) {
      giftCondition.giftData.giftId = String(context.root.$route.query.giftId);
      giftCondition.giftData.quantity = 1;
      giftCondition.addGiftCondition();
    }

    watch(
      () => context.root.$route.fullPath,
      () => giftCondition.init()
    );

    watch(
      () => [
        giftCondition.item.giftConditionType,
        giftCondition.item.subscriptionPlanId,
        giftCondition.item.productId,
        giftCondition.item.paymentCount,
        giftCondition.durationProperty.durationPeriod.interval,
        giftCondition.durationProperty.durationPeriod.intervalCount,
      ],
      () => {
        giftCondition.isDeletedFlg = false; // 画面に表示する特典条件一覧が変わる時、削除フラグもリセットする
        giftCondition.getRegisteredItems();
      }
    );

    return {
      pageTitle: '特典条件',
      myRoleSettings,
      giftCondition,
      GIFT_CONDITION_TYPE,
      displayGiftConditionId,
      isSaving,
      active,
      openDialog,
      editDialog,
      headers,
      cancel,
      add,
      remove,
      items,
      getOptionsTitle,
      giftConditionTypeOptions,
      intervalOptions,
      save,
      DisplayDate,
      checkExpiredGift,
      isRegister,
      isEdit,
    };
  },
});
