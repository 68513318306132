import core from '@/admin/core';
import { computed, reactive, Ref, ref } from '@vue/composition-api';
import { DisplayDate, getOptionsTitle, valueOf } from '@/admin/util';
import { usePayment } from '@/admin/payment';
import myAttributes from '@/composition/myAttributes';
import { getProducts } from './product';
const { getSubscriptionPlans } = usePayment();

interface Interval {
  // きもいけど一旦そのまま
  interval: 'day' | 'week' | 'month' | 'year';
  intervalCount: number;
}

export interface GiftResponse {
  giftId: string;
  createDate: number;
  annotation?: string;
  description?: string;
  expirationPeriod?: Interval;
  expirationType?: 'date' | 'period';
  expirationDate?: number;
  giftName: string;
  isArchive: boolean;
  thumbnail: string;
  useStartDate?: number;
  useEndDate?: number;
  isUsable: boolean;
  linkText?: string;
  linkUrl?: string;
  useImagePath?: string;
  category?: string[];
  isExternalLink: boolean;
  isExpiredGift: boolean;
}

export interface GiftProperty {
  giftId: string;
  createDate?: DisplayDate;
  annotation?: string;
  description?: string;
  expirationPeriod?: Interval;
  expirationType?: 'date' | 'period';
  expirationDate?: number;
  giftName: string;
  isArchive: boolean;
  thumbnail: string;
  useStartDate: DisplayDate;
  useEndDate: DisplayDate;
  isUsable: boolean;
  linkText?: string;
  linkUrl?: string;
  useImagePath?: string;
  category?: string;
  isExpiredGift: boolean;
}

interface GiftProps {
  giftName: string;
  description?: string;
  annotation?: string;
  thumbnail: string;
  expirationType?: 'date' | 'period';
  expirationDate?: number;
  expirationPeriod?: Interval;
  useStartDate?: number;
  useEndDate?: number;
  isUsable: boolean;
  linkText?: string;
  linkUrl?: string;
  isExternalLink?: boolean;
  useImagePath?: string;
  category?: string[];
}

export const GIFT_CONDITION_TYPE = {
  EACH_SUBSCRIPTION_PAYMENT: 'eachSubscriptionPayment',
  SUBSCRIPTION_PAYMENT_COUNT: 'subscriptionPaymentCount',
  PURCHASE_PRODUCT: 'purchaseProduct',
  DURATION: 'duration',
  BIRTHDAY: 'birthday',
} as const;
export type GiftConditionType = valueOf<typeof GIFT_CONDITION_TYPE>;

export interface GiftConditionResponse {
  giftConditionType: GiftConditionType;
  giftConditionId: string;
  subscriptionPlanId?: string;
  productId?: string;
  paymentCount?: number;
  durationPeriod?: Interval;
  createDate: number;
  gifts: GiftConditionGifts[];
}
export interface GiftConditionProperty {
  giftConditionType: GiftConditionType;
  giftConditionTitle?: string;
  giftConditionId: string;
  paymentCount?: number;
  durationPeriod?: Interval;
  createDate?: DisplayDate;
  gifts: GiftConditionGifts[];
  subscriptionPlanId?: string;
  subscriptionPlanName?: string;
  productId?: string;
  productName?: string;
  duration?: string;
}

export interface GiftConditionProps {
  // きもいけど一旦そのまま
  giftConditionType: GiftConditionType | string;
  gifts: GiftConditionGifts[];
  subscriptionPlanId?: string;
  productId?: string;
  paymentCount?: number;
  durationPeriod?: Interval;
}

// 特典条件全件取得APIからの返却値の型
interface GiftConditionGifts {
  giftId: string;
  quantity: number;
  activeStartDate?: number;
  activeEndDate?: number;
  isExpiredGift: boolean; // 特典自体の有効期限が切れているかのフラグ
  isInActivePeriodGift: boolean; // 特典条件が有効期限内かのフラグ
}

// 特典条件全件取得APIからの返却値に加えて編集フラグを持たせる
export interface GiftData extends GiftConditionGifts {
  isEdit: boolean;
}

export interface GiftItem {
  text: string;
  value: string;
  isExpiredGift: boolean;
}

export const giftConditionTypeOptions = [
  { text: 'プラン決済：都度', value: GIFT_CONDITION_TYPE.EACH_SUBSCRIPTION_PAYMENT },
  { text: 'プラン決済：累計回数', value: GIFT_CONDITION_TYPE.SUBSCRIPTION_PAYMENT_COUNT },
  { text: '商品購入', value: GIFT_CONDITION_TYPE.PURCHASE_PRODUCT },
  { text: '継続日数', value: GIFT_CONDITION_TYPE.DURATION },
  { text: '誕生日', value: GIFT_CONDITION_TYPE.BIRTHDAY },
];

export const intervalOptions = [
  {
    value: 'day',
    text: '日間',
  },
  {
    value: 'week',
    text: '週間',
  },
  {
    value: 'month',
    text: 'ヶ月',
  },
  {
    value: 'year',
    text: '年間',
  },
];

export const expirationTypeItems = [
  {
    value: 'date',
    text: '日付指定',
  },
  {
    value: 'period',
    text: '期間指定',
  },
];

export const expirationPeriodIntervalItems = [
  {
    value: 'day',
    text: '日',
  },
  {
    value: 'week',
    text: '週',
  },
  {
    value: 'month',
    text: '月',
  },
  {
    value: 'year',
    text: '年',
  },
];

export const getExpirationTypeText = (value: 'date' | 'period') => {
  return expirationTypeItems.find((item) => item.value === value)?.text || '';
};

export const getExpirationPeriodIntervalText = (value: 'day' | 'week' | 'month' | 'year') => {
  return expirationPeriodIntervalItems.find((item) => item.value === value)?.text || '';
};

// 特典一覧取得API
export const getGifts = async () => {
  if (myAttributes.myRequestPermissions?.gifts) {
    const result = await core.httpClient.get('/admin/public/gifts');
    return result.data as GiftResponse[];
  } else return [] as GiftResponse[];
};

// 特典一件取得API
export const getGift = async (giftId: string) => {
  if (myAttributes.myRequestPermissions?.gifts) {
    const result = await core.httpClient.get(`/admin/public/gifts/${giftId}`);
    return result.data as GiftResponse;
  } else return {} as GiftResponse;
};

// 特典条件全件取得API
export const getGiftConditions = async () => {
  if (myAttributes.myRequestPermissions?.giftConditions) {
    const result = await core.httpClient.get('/admin/public/giftConditions');
    return result.data as GiftConditionResponse[];
  } else return [] as GiftConditionResponse[];
};

// 特典条件一件取得API
export const getGiftCondition = async (giftConditionId: string, giftConditionType: string) => {
  if (myAttributes.myRequestPermissions?.giftConditions) {
    const result = await core.httpClient.get(
      `/admin/public/giftConditions/${giftConditionId}?giftConditionType=${giftConditionType}`
    );
    return result.data as GiftConditionResponse;
  } else return {} as GiftConditionResponse;
};

export class Gifts {
  fetchLoading = false;
  items: GiftProperty[] = [];

  constructor() {
    this.init();
  }
  init = async () => {
    this.fetchLoading = true;
    this.items = await this.getGifts();
    this.fetchLoading = false;
  };
  getGifts = async (): Promise<GiftProperty[]> => {
    const _items = await getGifts();
    return _items
      .map((_item) => {
        const item: GiftProperty = {
          giftId: _item.giftId,
          createDate: new DisplayDate(_item.createDate),
          annotation: _item.annotation || '',
          description: _item.description || '',
          giftName: _item.giftName,
          isArchive: _item.isArchive,
          thumbnail: _item.thumbnail,
          useStartDate: new DisplayDate(_item.useStartDate),
          useEndDate: new DisplayDate(_item.useEndDate),
          isUsable: _item.isUsable,
          linkText: _item.linkText || '',
          linkUrl: _item.linkUrl || '',
          useImagePath: _item.useImagePath || '',
          category: _item.category?.join(','),
          isExpiredGift: _item.isExpiredGift,
        };
        if (_item.expirationType) item.expirationType = _item.expirationType;
        if (_item.expirationPeriod) item.expirationPeriod = _item.expirationPeriod;
        if (_item.expirationDate) item.expirationDate = _item.expirationDate;
        return item;
      })
      .sort((a, b) => (b.createDate?.value && a.createDate?.value ? b.createDate.value - a.createDate.value : -1));
  };

  deleteGift = async (giftId: string) => {
    await core.httpClient.delete(`/admin/public/gifts/${giftId}`);
  };
}

export class Gift {
  giftId = '';
  fetchLoading = false;
  isSaving = false;
  isExpiration = false;
  isUseStartDate = false;
  isUseEndDate = false;
  isPutButton = false;
  item: GiftProperty = {
    giftId: '',
    createDate: new DisplayDate(),
    annotation: '',
    description: '',
    giftName: '',
    isArchive: false,
    thumbnail: '',
    useStartDate: new DisplayDate(),
    useEndDate: new DisplayDate(),
    isUsable: false,
    useImagePath: '',
    category: '',
    isExpiredGift: false,
  };
  buttonProperty = {
    linkText: '',
    linkUrl: '',
    isExternalLink: false,
  };
  expirationProperty: {
    expirationType: 'date' | 'period';
    expirationDate: DisplayDate;
    expirationPeriod: Interval;
  } = {
    expirationType: 'date',
    expirationDate: new DisplayDate(),
    expirationPeriod: {
      interval: 'day',
      intervalCount: 1,
    },
  };
  props: GiftProps | null = null;

  constructor(giftId?: string) {
    if (giftId) {
      this.giftId = giftId;
      this.init();
    }
  }

  init = async () => {
    this.fetchLoading = true;
    this.item = await this.getGift(this.giftId);
    this.fetchLoading = false;
  };

  getGift = async (giftId: string): Promise<GiftProperty> => {
    if (myAttributes.myRequestPermissions?.gifts) {
      const result = await core.httpClient.get(`/admin/public/gifts/${giftId}`);
      const _item = result.data as GiftResponse;
      const item: GiftProperty = {
        giftId: _item.giftId,
        annotation: _item.annotation || '',
        description: _item.description || '',
        giftName: _item.giftName,
        isArchive: _item.isArchive,
        thumbnail: _item.thumbnail,
        useStartDate: new DisplayDate(_item.useStartDate),
        useEndDate: new DisplayDate(_item.useEndDate),
        isUsable: _item.isUsable,
        useImagePath: _item.useImagePath || '',
        category: _item.category?.join(','),
        isExpiredGift: _item.isExpiredGift,
      };
      if (_item.useStartDate) {
        this.isUseStartDate = true;
      }
      if (_item.useEndDate) {
        this.isUseEndDate = true;
      }
      if (_item.linkUrl || _item.linkText) {
        // 最初テキストなしのURLのみでも保存できていたので、条件としてはどちらかがあれば値を入れるようにする
        this.isPutButton = true;
        this.buttonProperty.linkText = _item.linkText || '';
        this.buttonProperty.linkUrl = _item.linkUrl || '';
        this.buttonProperty.isExternalLink = _item.isExternalLink;
      }
      if (_item.expirationType) {
        this.isExpiration = true;
        this.expirationProperty.expirationType = _item.expirationType;
        this.expirationProperty.expirationPeriod = _item.expirationPeriod || this.expirationProperty.expirationPeriod;
        this.expirationProperty.expirationDate = new DisplayDate(_item.expirationDate);
      }
      return item;
    }
    return {} as GiftProperty;
  };

  createProps = () => {
    if (!this.item.giftName || !this.item.thumbnail) throw '特典名, サムネイルが未入力です';
    else if (
      this.isExpiration &&
      this.expirationProperty.expirationType === 'date' &&
      this.expirationProperty.expirationDate.value === undefined
    ) {
      throw '不正な日時です';
    } else if (
      this.isExpiration &&
      this.expirationProperty.expirationDate.value &&
      this.isUseEndDate &&
      this.item.useEndDate.value &&
      this.expirationProperty.expirationDate.value < this.item.useEndDate.value
    ) {
      throw 'もぎり終了日が有効期限より後になっています';
    } else if (
      this.isExpiration &&
      this.expirationProperty.expirationDate.value &&
      this.isUseEndDate &&
      this.isUseStartDate &&
      this.item.useStartDate.value &&
      this.expirationProperty.expirationDate.value < this.item.useStartDate.value
    ) {
      throw 'もぎり開始日が有効期限より後になっています';
    } else if (this.isPutButton && (!this.buttonProperty.linkText || !this.buttonProperty.linkUrl)) {
      throw 'ボタンを配置する場合はURLとボタンテキストが必須です';
    } else if (
      this.isPutButton &&
      !this.buttonProperty.linkUrl.match(/^(https?|ftp)(:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)$/)
    ) {
      throw 'リンクが不正です。ドメインから指定してください';
    } else if (this.item.isUsable && !this.item.useImagePath) {
      throw 'もぎり用の画像が未入力です';
    } else {
      this.props = {
        giftName: this.item.giftName,
        isUsable: this.item.isUsable,
        thumbnail: this.item.thumbnail,
        useImagePath: this.item.useImagePath || '',
      };
      if (this.item.category) {
        this.props.category = this.item.category
          .split(',')
          .map((category) => category.trim())
          .filter((category) => !!category);
      }
      if (this.item.description) this.props.description = this.item.description;
      if (this.item.annotation) this.props.annotation = this.item.annotation;
      if (this.isPutButton) {
        this.props.linkText = this.buttonProperty.linkText;
        this.props.linkUrl = this.buttonProperty.linkUrl;
        this.props.isExternalLink = this.buttonProperty.isExternalLink;
      }
      if (this.isExpiration && this.expirationProperty.expirationDate) {
        this.props.expirationType = this.expirationProperty.expirationType;
        this.props.expirationPeriod = this.expirationProperty.expirationPeriod;
        this.props.expirationDate = this.expirationProperty.expirationDate.value;
      }
      if (this.isUseStartDate && this.item.useStartDate.value) {
        this.props.useStartDate = this.item.useStartDate.value;
      }
      if (this.isUseEndDate && this.item.useEndDate.value) {
        this.props.useEndDate = this.item.useEndDate.value;
      }
    }
  };

  saveGift = async (giftId: string) => {
    this.createProps();
    if (!this.props) return;
    await core.httpClient.put(`/admin/public/gifts/${giftId}`, this.props);
  };

  createGift = async () => {
    this.createProps();
    if (!this.props) return;
    await core.httpClient.post('/admin/public/gifts', this.props);
  };
}

export class GiftConditions {
  fetchLoading = false;
  items: GiftConditionProperty[] = [];

  constructor() {
    this.init();
  }

  init = async () => {
    this.fetchLoading = true;
    this.items = await this.getGiftConditions();
    this.fetchLoading = false;
  };

  getGiftConditions = async () => {
    const [subscriptionPlans, products, gifts, _items] = await Promise.all([
      getSubscriptionPlans(),
      getProducts(),
      getGifts(),
      getGiftConditions(),
    ]);

    return _items
      .map((_item) => {
        const item: GiftConditionProperty = {
          giftConditionType: _item.giftConditionType,
          giftConditionId: _item.giftConditionId,
          subscriptionPlanId: _item.subscriptionPlanId || '',
          productId: _item.productId || '',
          createDate: new DisplayDate(_item.createDate),
          gifts: _item.gifts.map((gift) => {
            return {
              giftId: gift.giftId,
              quantity: gift.quantity,
              giftName: gifts.find((item) => item.giftId === gift.giftId)?.giftName,
              activeStartDate: gift.activeStartDate ? gift.activeStartDate : undefined,
              activeEndDate: gift.activeEndDate ? gift.activeEndDate : undefined,
              isExpiredGift: gift.isExpiredGift,
              isInActivePeriodGift: gift.isInActivePeriodGift,
            };
          }),
        };
        if (_item.paymentCount) item.paymentCount = _item.paymentCount;

        // subscriptionPlanIdがあれば、planNameを作成してあげる
        if (_item.subscriptionPlanId) {
          item.subscriptionPlanName = subscriptionPlans.find(
            (subscriptionPlan) => subscriptionPlan.subscriptionPlanId === _item.subscriptionPlanId
          )?.subscriptionPlanName;
        }
        if (_item.productId) {
          item.productName = products.find((product) => product.productId === _item.productId)?.productName;
        }

        // 会員登録からの継続日が入っていれば、入れてあげる（1日/1ヶ月/1年間）
        if (_item.durationPeriod)
          item.duration =
            _item.durationPeriod.intervalCount + getOptionsTitle(intervalOptions, _item.durationPeriod.interval);

        // 条件のテキストを作成する
        let giftConditionTitle = '';
        if (item.giftConditionType === GIFT_CONDITION_TYPE.EACH_SUBSCRIPTION_PAYMENT && item.subscriptionPlanName) {
          giftConditionTitle = item.subscriptionPlanName;
        } else if (
          item.giftConditionType === GIFT_CONDITION_TYPE.SUBSCRIPTION_PAYMENT_COUNT &&
          item.subscriptionPlanName &&
          item.paymentCount
        ) {
          giftConditionTitle = `${item.subscriptionPlanName}: ${item.paymentCount}回`;
        } else if (item.giftConditionType === GIFT_CONDITION_TYPE.PURCHASE_PRODUCT && item.productName) {
          giftConditionTitle = item.productName;
        } else if (item.giftConditionType === GIFT_CONDITION_TYPE.DURATION) {
          giftConditionTitle = item.duration || '';
        }

        item.giftConditionTitle = giftConditionTitle;
        return item;
      })
      .sort((a, b) => (b.createDate?.value && a.createDate?.value ? b.createDate.value - a.createDate.value : -1));
  };

  deleteGiftCondition = async (giftConditionId: string, giftConditionType: string) => {
    await core.httpClient.delete(
      `/admin/public/giftConditions/${giftConditionId}?giftConditionType=${giftConditionType}`
    );
  };
}

export class GiftCondition {
  isSaving = false;
  fetchLoading = false;
  giftConditionId = '';
  giftConditionType = '';
  giftItems: GiftItem[] = [];
  subscriptionPlanNames: { text: string; value: string; isArchive: boolean }[] = [];
  productNames: { text: string; value: string; isArchive: boolean }[] = [];
  isSubscriptionPlanArchive = false;
  isProductArchive = false;
  items: GiftConditionResponse[] = [];
  _durationProperty: Ref<{
    durationPeriod: Interval;
  }> = ref<{ durationPeriod: Interval }>({
    durationPeriod: {
      interval: 'day',
      intervalCount: 1,
    },
  });

  _item = ref<GiftConditionProps>({
    giftConditionType: GIFT_CONDITION_TYPE.EACH_SUBSCRIPTION_PAYMENT,
    gifts: [], // 編集前の特典条件一覧を保持する配列
  });
  _viewGifts: Ref<GiftData[]> = ref([]); // 編集画面の特典条件一覧用配列
  editItems: { isEdit: boolean; index?: number } = { isEdit: false }; // 編集した特典条件の判定のためのオブジェクト
  isDeletedFlg = false; // 編集で削除された特典条件があるかを保持するフラグ
  registerDuplicatedItems: GiftData[] = []; // 追加する特典条件の中から編集済みかつ付与する対象の特典が重複している特典条件の配列

  // 付与する対象の特典重複判定用
  _registerAlertItems = computed(() => {
    const duplicatedId = this.viewGifts
      .map((viewGift, index) => {
        const isDuplicated = this.viewGifts.some(
          (item: GiftData, i: number) => item.giftId === viewGift.giftId && index !== i
        );
        return isDuplicated && viewGift.isEdit ? viewGift.giftId : '';
      })
      .filter((id) => id);
    const duplicatedSet = new Set(duplicatedId);
    return Array.from(duplicatedSet);
  });

  // 特典条件追加ダイアログ表示用のデータ(有効期限は表示用の型の違いから別で保持し、ダイアログにisInActivePeriodGiftは不要であるが、一覧表示で必要なため型を合わせる)
  _giftData: Ref<GiftData> = ref({
    giftId: '',
    quantity: 1,
    isEdit: false,
    isExpiredGift: false,
    isInActivePeriodGift: false,
  });

  // 特典条件追加ダイアログの有効期限を保持する
  expirationStartProperty: {
    expirationDate: DisplayDate;
  } = { expirationDate: new DisplayDate() };
  expirationEndProperty: {
    expirationDate: DisplayDate;
  } = { expirationDate: new DisplayDate() };

  get registerAlertItems() {
    return this._registerAlertItems.value;
  }
  get item() {
    return this._item.value;
  }
  set item(item: GiftConditionProps) {
    this._item.value = item;
  }
  get giftData() {
    return this._giftData.value;
  }
  set giftData(giftData: GiftData) {
    this._giftData.value = giftData;
  }
  get durationProperty() {
    return this._durationProperty.value;
  }
  set durationProperty(durationProperty: { durationPeriod: Interval }) {
    this._durationProperty.value = durationProperty;
  }
  get viewGifts() {
    return this._viewGifts.value;
  }
  set viewGifts(_viewGifts) {
    this._viewGifts.value = _viewGifts;
  }

  constructor(giftConditionId?: string, giftConditionType?: string) {
    if (giftConditionId && giftConditionType) {
      this.giftConditionId = giftConditionId;
      this.giftConditionType = giftConditionType;
    }
    this.init();
  }

  init = async () => {
    this.fetchLoading = true;
    const [subscriptionPlan, products, gifts, _item, _items] = await Promise.all([
      getSubscriptionPlans(),
      getProducts(),
      getGifts(),
      this.getGiftCondition(),
      getGiftConditions(),
    ]);

    // 特典名を表示するため、必要なもので配列を作成
    this.giftItems = gifts
      .filter((gift) => !gift.isArchive)
      .map((gift) => {
        return {
          text: gift.giftName,
          value: gift.giftId,
          isExpiredGift: gift.isExpiredGift,
        };
      });

    // プラン名を表示するため、必要なもので配列を作成
    this.subscriptionPlanNames = subscriptionPlan.map((subscriptionPlan) => {
      return {
        text: subscriptionPlan.isArchive
          ? `【削除済み】${subscriptionPlan.subscriptionPlanName}`
          : subscriptionPlan.subscriptionPlanName,
        value: subscriptionPlan.subscriptionPlanId,
        isArchive: subscriptionPlan.isArchive,
      };
    });

    this.productNames = products.map((product) => {
      return {
        text: product.isArchive ? `【削除済み】${product.productName}` : product.productName,
        value: product.productId,
        isArchive: product.isArchive,
      };
    });

    this.isSubscriptionPlanArchive = !!subscriptionPlan.find(
      (subscriptionPlan) => subscriptionPlan.subscriptionPlanId === _item.subscriptionPlanId
    )?.isArchive;

    this.isProductArchive = !!products.find((product) => product.productId === _item.productId)?.isArchive;
    if (_item.durationPeriod) {
      this.durationProperty.durationPeriod = _item.durationPeriod;
    }

    this.item = _item;
    this.items = _items;
    this.viewGifts = this.item.gifts.map((gift) => ({ ...gift, isEdit: false }));
    this.fetchLoading = false;
  };

  getGiftCondition = async () => {
    if (!this.giftConditionId && !this.giftConditionType) return this.item;
    this.fetchLoading = true;
    const _item = await getGiftCondition(this.giftConditionId, this.giftConditionType);
    const item: GiftConditionProps = {
      giftConditionType: _item.giftConditionType,
      gifts: [],
    };
    if (_item.subscriptionPlanId) item.subscriptionPlanId = _item.subscriptionPlanId;
    if (_item.productId) item.productId = _item.productId;
    if (_item.paymentCount) item.paymentCount = _item.paymentCount;
    if (_item.durationPeriod) item.durationPeriod = _item.durationPeriod;

    return item;
  };

  // 付与する特典条件追加時の処理
  addGiftCondition = () => {
    if (!this.editItems.isEdit && this.editItems.index === undefined) {
      // 新規追加の場合(isInActivePeriodGiftは初期値のfalseだが、利用するのはステータス表示のところで、ステータスは編集中とする)
      this.giftData.activeStartDate = this.expirationStartProperty.expirationDate.value;
      this.giftData.activeEndDate = this.expirationEndProperty.expirationDate.value;
      this.giftData.isEdit = true;
      this.viewGifts = [...this.viewGifts, this.giftData];
    } else if (this.editItems.isEdit && this.editItems.index !== undefined) {
      // 編集の場合(配列を直接置き換える場合は変更が検知できない？ため、spliceで置き換える)
      this.viewGifts.splice(this.editItems.index, 1, {
        giftId: this.giftData.giftId,
        quantity: this.giftData.quantity,
        isExpiredGift: this.giftData.isExpiredGift,
        isInActivePeriodGift: this.giftData.isInActivePeriodGift, // バックエンドから判定結果を取得しない限り編集後の有効フラグは不明なため、一旦そのままにしておく(利用するのはステータス表示のところで、編集中の場合は編集中のステータスとする)
        activeStartDate: this.expirationStartProperty.expirationDate.value,
        activeEndDate: this.expirationEndProperty.expirationDate.value,
        isEdit: true,
      });
    }
    // 特典条件追加ダイアログの表示はリセットする
    this.giftData = {
      giftId: '',
      quantity: 1,
      isEdit: false,
      isExpiredGift: false,
      isInActivePeriodGift: false,
    };
    // 有効期限の表示等についてもリセットする
    this.expirationStartProperty.expirationDate = new DisplayDate();
    this.expirationEndProperty.expirationDate = new DisplayDate();
    this.editItems = { isEdit: false };
  };

  createProps = () => {
    if (!this.item.giftConditionType || !this.viewGifts.length) throw '必須項目が未入力です';

    const giftConditionType = this.item.giftConditionType;
    if (giftConditionType === GIFT_CONDITION_TYPE.EACH_SUBSCRIPTION_PAYMENT) {
      if (!this.item.subscriptionPlanId) throw '必須項目が未入力です';
    } else if (giftConditionType === GIFT_CONDITION_TYPE.EACH_SUBSCRIPTION_PAYMENT) {
      if (!this.item.subscriptionPlanId || !this.item.paymentCount) throw '必須項目が未入力です';
    } else if (giftConditionType === GIFT_CONDITION_TYPE.PURCHASE_PRODUCT) {
      if (!this.item.productId) throw '必須項目が未入力です';
    } else if (giftConditionType === GIFT_CONDITION_TYPE.DURATION) {
      if (!this.durationProperty.durationPeriod) throw '必須項目が未入力です';
    }

    const props: GiftConditionProps = {
      giftConditionType: giftConditionType,
      gifts: this.viewGifts,
    };
    if (props.giftConditionType === GIFT_CONDITION_TYPE.EACH_SUBSCRIPTION_PAYMENT) {
      props.subscriptionPlanId = this.item.subscriptionPlanId;
    } else if (props.giftConditionType === GIFT_CONDITION_TYPE.SUBSCRIPTION_PAYMENT_COUNT) {
      props.subscriptionPlanId = this.item.subscriptionPlanId;
      props.paymentCount = this.item.paymentCount;
    } else if (props.giftConditionType === GIFT_CONDITION_TYPE.PURCHASE_PRODUCT) {
      props.productId = this.item.productId;
    } else if (props.giftConditionType === GIFT_CONDITION_TYPE.DURATION) {
      props.durationPeriod = this.durationProperty.durationPeriod;
    }
    return props;
  };

  // 特典条件更新API
  saveGiftCondition = async (giftConditionId: string) => {
    const props = this.createProps();
    await core.httpClient.put(`/admin/public/giftConditions/${giftConditionId}`, props);
  };

  // 特典条件作成API
  createGiftCondition = async () => {
    const props = this.createProps();
    await core.httpClient.post('/admin/public/giftConditions', props);
  };

  // 付与する特典条件一覧を取得する
  getRegisteredItems = () => {
    const giftConditionType = this.item.giftConditionType;
    // 既に登録済みの特典条件一覧から表示する一覧を取得する
    const targetGiftCondition = this.items.find((item) => {
      if (item.giftConditionType !== giftConditionType) return false;
      if (giftConditionType === GIFT_CONDITION_TYPE.EACH_SUBSCRIPTION_PAYMENT) {
        // プラン決済都度:プランにつき一つの特典条件しか作成できない
        return item.subscriptionPlanId === this.item.subscriptionPlanId;
      } else if (giftConditionType === GIFT_CONDITION_TYPE.SUBSCRIPTION_PAYMENT_COUNT) {
        // プラン決済累計回数:各プランの決済回数につき一つの特典条件しか作成できない
        const isSameSubscriptionPlanId = item.subscriptionPlanId === this.item.subscriptionPlanId;
        const isSamePaymentCount = item.paymentCount === this.item.paymentCount;
        return isSameSubscriptionPlanId && isSamePaymentCount;
      } else if (giftConditionType === GIFT_CONDITION_TYPE.PURCHASE_PRODUCT) {
        // 商品購入:商品につき一つの特典条件しか作成できない
        return item.productId === this.item.productId;
      } else if (giftConditionType === GIFT_CONDITION_TYPE.DURATION) {
        if (!this.item.durationPeriod) return false;
        // 継続日数:各新規会員登録からの継続日数につき一つの特典条件しか作成できない
        const isSameInterval = this.item.durationPeriod.interval === item.durationPeriod?.interval;
        const isSameIntervalCount = this.item.durationPeriod.intervalCount === item.durationPeriod?.intervalCount;
        return isSameInterval && isSameIntervalCount;
      } else if (giftConditionType === GIFT_CONDITION_TYPE.BIRTHDAY) {
        // 誕生日:一つの特典条件しか作成できない
        return true;
      }
    });
    if (!targetGiftCondition) {
      this.viewGifts = [];
      this.giftConditionId = '';
      return;
    }
    this.giftConditionId = targetGiftCondition.giftConditionId;
    this.giftConditionType = targetGiftCondition.giftConditionType;
    const displayGifts: GiftData[] = targetGiftCondition.gifts.map((gift) => ({ ...gift, isEdit: false }));
    this.viewGifts = displayGifts;
  };
}

export const useGifts = () => {
  const gifts = new Gifts();
  return { gifts: reactive(gifts) };
};

export const useGift = (giftId: string) => {
  const gift = new Gift(giftId);
  return { gift: reactive(gift) };
};

export const useGiftConditions = () => {
  const giftConditions = new GiftConditions();
  return { giftConditions: reactive(giftConditions) };
};

export const useGiftCondition = (giftConditionId: string, giftConditionType: string) => {
  const giftCondition = new GiftCondition(giftConditionId, giftConditionType);
  return { giftCondition: giftCondition };
};
